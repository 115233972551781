import React, { useState } from "react";
import "../styles/cyberSecurity.css";
//import cyberSecurity from "../assets/svgs/cyberSecurity.svg";
import Accordian from "./Accordian";
//import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import SectionTitle from "../reusable/SectionTitle";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
// import SectionSubTitle from "../reusable/SectionSubTitle";

const CyberSecurity = () => {
  const [expandedAccordianNo, setExpandedAccordianNo] = useState(0);
  const handleOnExpand = (index) => {
    if (expandedAccordianNo === index) {
      setExpandedAccordianNo(-1);
    } else {
      setExpandedAccordianNo(index);
    }
  };
  const stats = [
    <>
      استهدفت <span dir="ltr">43%</span> من الهجمات الإلكترونية
      الشركات الصغيرة في عام 2021.
    </>,
    <>
      زادت هجمات برامج طلب فدية بنسبة <span dir="ltr">365%</span>
      منذ عام 2018.
    </>,
    <>
      في عام 2021، بلغ متوسط تكلفة انتهاك البيانات 3.86 مليون دولار و 
      <span dir="ltr">95%</span> من انتهاكات البيانات تحدث نتيجة خطأ بشري.
    </>,
    <>
      مع الإبلاغ يوميًا عن أكثر من 350000 حالة جديدة للبرامج الضارة، يتصدى مجال
      الأمن الإلكتروني إلى مجموعة متزايدة من التهديدات.
    </>,
    <>
      تعتقد <span dir="ltr">94%</span> من المؤسسات أن الامتثال
      للوائح حماية البيانات يمثل أولوية قصوى، ما ينتج سوقًا كبيرة لحلول الأمن
      الإلكتروني المتماثلة.
    </>,
    <>
      أدى اعتماد مبدأ العمل عن بُعد إلى زيادة بنسبة {" "}
      <span dir="ltr">600%</span> في الهجمات الإلكترونية، ما يبرز الحاجة
      إلى حلول الأمن الإلكتروني المصممة خصوصًا لعرض العمل الجديد.
    </>,
  ];

  return (
    <section
      id="cyberSecurity"
      className="py-[110px] min-h-[100vh] px-[66px] cyberParent"
    >
      <div className="tablet:flex tablet:flex-row-reverse">
        <div className="tablet:w-[30vw]">
          {/* <AnimateOnDisplay
            animationClass={"snap-in"}
            className={`space-y-[20px] uppercase laptop:text-[108px] tablet:text-[60px] leading-none text-[50px]  text-[#111748]`}
          >
            <div className=" font-[300]">Cyber</div>
            <div className="font-[900]">Security</div>
          </AnimateOnDisplay> */}
          <SectionTitle
            className={`space-y-[20px] text-right uppercase laptop:text-[108px] tablet:text-[60px] leading-none text-[50px]  text-[#111748]`}
            firstChild={<div className=" font-[300]">الأمن</div>}
            secondChild={<div className="font-[900]">الإلكتروني</div>}
            viewPortStartPointFromBottom={0}
            viewPortEndPointFromBottom={50}
            firstChildTranslateAtStart={window.innerWidth * 0.2}
          />
          <p className="text-[#111748CC] text-right mt-4 text-[21px] tablet:text-[22px] laptop:text-[25px] leading-none font-[500]">
            التحقق من المعلومات{" "}
          </p>
          {/* <SectionSubTitle
            text={"Facts Check"}
            className={
              "text-[#111748CC] mt-4 text-[21px] tablet:text-[22px] laptop:text-[25px] leading-none font-[500]"
            }
          /> */}
          <ul
            dir="rtl"
            className="text-[#111748CC] text-right text-[16px] tablet:text-[19px] laptop:text-[22px] my-[20px] space-y-4"
            style={{ listStyleType: "none", paddingRight: "16px" }}
          >
            {stats.map((stat, index) => (
              <AnimateOnDisplay
                key={index}
                animationClass={"snap-in"}
                screenPercentHeightToStartAnimation={90}
                className={"opacity-0"}
                animateEveryTime={false}
              >
                <li className="listItemStyle">
                  <span className="mr-5 block">{stat}</span>
                </li>
              </AnimateOnDisplay>
            ))}
          </ul>
        </div>
        <div className="tablet:max-w-[50vw]">
          {accordianList.map((accordianItem, i) => (
            <Accordian
              key={i}
              isLast={i === accordianList.length - 1}
              isExpanded={expandedAccordianNo === i}
              onExpand={() => handleOnExpand(i)}
              title={accordianItem.title}
              description={accordianItem.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CyberSecurity;

const accordianDescriptionHeadingClass =
  "font-[500] text-[19px] tablet:text-[20px] laptop:text-[21px]";

const accordianList = [
  {
    title: "اختبار الاختراق",
    description: (
      <div>
        اختبار الاختراق، المعروف أيضًا باسم اختبار Pen، هو هجوم إلكتروني مُحاكي
        على نظام كمبيوتر أو شبكة أو تطبيق ويب لتقييم مدى أمانه. يتمثل الهدف من
        هذا الاختبار في تحديد الثغرات وتقييم الوضع الأمني العام للهدف، بغرض
        تحسينه.
        <br />
        <br />
        يستخدم القائمون على اختبار الاختراق تكنولوجيات مختلفة، بما في ذلك عمليات
        المسح الضوئي الآلي والاستكشاف اليدوي، لمحاولة العثور على الثغرات
        واستغلالها. يساعد هذا في تحديد الثغرات التي قد لا تنتبه لها الإجراءات
        الأمنية الأخرى، مثل جدران الحماية وبرامج مكافحة الفيروسات. يمكن بعد ذلك
        استخدام نتائج اختبار الاختراق لوضع خطط الإصلاح وتحسين أمن الهدف.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          فيما يلي 5 أسباب وراء أهمية اختبار الاختراق لمؤسستك:{" "}
        </span>
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>الامتثال:</span>
         تتطلب بعض القوانين واللوائح، مثل القانون العام لحماية البيانات
        (GDPR) ومعيار أمان بيانات صناعة بطاقات الدفع (PCI DSS) وقانون إخضاع
        التأمين الصحي لقابلية النقل والمحاسبة (HIPAA)، من المؤسسات تقييم وضعها
        الأمني بشكل دوري. يمكن أن يساعد اختبار الاختراق المؤسسات على تلبية هذه
        المتطلبات وإثبات التزامها بالأمان.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>المسؤولية:</span>
         من خلال إجراء اختبار الاختراق بشكل دوري، يمكن للمؤسسات تحديد
        المخاطر الأمنية المحتملة والحد منها قبل أن يتمكن المهاجمون من استغلالها.
        ويمكن أن يساعد هذا في تقليل مخاطر انتهاكات البيانات، التي ربما تؤدي إلى
        تكبد خسائر مالية كبيرة والإضرار بالسمعة وتحمل للمسؤولية القانونية.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>العقود:</span>
        قد يُطلب من المؤسسات في بعض الحالات إجراء اختبار الاختراق كشرط من
        شروط العقد، مثل اتفاق مستوى الخدمة مع مزود خدمات سحابية. يمكن أن يساعد
        اختبار الاختراق المؤسسات على إثبات وفائها بالتزاماتها بموجب هذه
        الاتفاقيات.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          إجراءات الفحص اللازمة:
        </span>
         في سياق عمليات الدمج والشراء، قد يُطلب من المؤسسات إجراء اختبار
        الاختراق كجزء من عملية إجراءات الفحص اللازمة لها. يمكن أن يساعد ذلك في
        تحديد المخاطر الأمنية المحتملة التي يمكن أن تؤثر على قيمة المعاملة.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>الدليل:</span>
        في حالة حدوث انتهاك للبيانات أو حادث أمني آخر، قد يُطلب من
        المؤسسات تقديم دليل على الخطوات التي اتخذتها لتأمين أنظمتها وهياكلها
        الأساسية. يمكن أن يساعد اختبار الاختراق في تقديم هذا الدليل وإثبات أن
        المؤسسة اتخذت ما يكفي من خطوات لحماية أنظمتها وبياناتها.
        <br />
        <br />
        يمكن أخيرًا لاختبار الاختراق، من منظور قانوني، أن يساعد المؤسسات على
        الوفاء بالمتطلبات التنظيمية والحد من مسؤوليتها والوفاء بالتزاماتها
        التعاقدية وإثبات إجراءات الفحص اللازمة وتقديم دليل على ما تبذله من جهود
        في مجال الأمان. من خلال إجراء اختبارات الاختراق بشكل دوري، يمكن للمؤسسات
        تحديد المخاطر المحتملة والحد منها
      </div>
    ),
  },
  {
    title: "عمليات التدقيق الأمني",
    description: (
      <div>
        التدقيق الأمني هو تقييم شامل للوضع الأمني في المؤسسة. يتمثل الغرض من
        التدقيق الأمني في تحديد الثغرات والمخاطر الأمنية المحتملة، وتقييم مدى
        كفاءة التدابير الأمنية القائمة، وتقديم توصيات لتحسين الأمان العام في
        المؤسسة.
        <br />
        <br />
        يتضمن التدقيق الأمني عادةً فحصًا تفصيليًا لأنظمة المؤسسة وشبكاتها
        وتطبيقاتها وهيكلها الأساسي. يمكن أن يشمل ذلك مراجعة السياسات والإجراءات
        والضوابط التكنولوجية الأمنية، فضلاً عن اختبار هذه الضوابط والتحقق منها
        لضمان فعاليتها. تقدم نتائج التدقيق الأمني إلى المؤسسات فهمًا واضحًا
        لوضعها الأمني، ومن ضمن ذلك أي نقاط ضعف أو ثغرات. يمكن استخدام هذه
        المعلومات لاتخاذ قرارات قائمة على الوعي فيما يخص الاستثمارات في
        التكنولوجيا والخدمات الأمنية، فضلاً عن إدخال تحسينات على السياسات
        والإجراءات الأمنية. يتكون الأمان عمومًا من الخدمات التالية:
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>تقييم الثغرات:</span>
         إجراء فحص منهجي لنظم المؤسسة وهياكلها الأساسية لتحديد الثغرات
        الأمنية المحتملة. <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          تقييم الامتثال:
        </span>
         تقييم امتثال المؤسسة للقوانين واللوائح المعنية، بما في ذلك
        اللوائح المتعلقة بخصوصية البيانات وأمانها. <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          تقييم الاستجابة للحوادث:
        </span>
         محاكاة هجمات الهندسة الاجتماعية، مثل التصيد الاحتيالي أو الخداع،
        لتقييم الوعي الأمني في المؤسسة وإمكانياتها على الاستجابة <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          تقييم الهندسة الاجتماعية:
        </span>
         محاكاة هجمات الهندسة الاجتماعية، مثل التصيد الاحتيالي أو الخداع،
        لتقييم الوعي الأمني في المؤسسة وإمكانياتها على الاستجابة <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          مراجعة رمز المصدر:
        </span>
         مراجعة الرمز هي عملية تتم فيها مراجعة رمز البرامج وتقييمه بحثًا
        عن الثغرات الأمنية والمخاطر المحتملة من أجل الحد منها <br />
        <br />
      </div>
    ),
  },
  {
    title: "برامج طلب فدية",
    description: (
      <div>
        برامج طلب فدية هي برامج ضارة تهدف لمنع المستخدم أو المؤسسة من الوصول إلى
        الملفات الموجودة على أجهزة الكمبيوتر الخاصة بهم. من خلال تشفير هذه
        الملفات والمطالبة بدفع فدية لمفتاح فك التشفير، يضع المهاجمون
        الإلكترونيون المؤسسات في موقف قد يكون فيه دفع الفدية أسهل طريقة لاستعادة
        الوصول إلى ملفاتهم.
        <br />
        <br />
        قد تهدد برامج طلب فدية أيضًا بنشر البيانات الشخصية أو منع الوصول إليها
        منعًا دائمًا ما لم يتم دفع فدية. غالبًا ما ينتشر هذا البرنامج الضار من
        خلال رسائل البريد الإلكتروني الاحتيالية التي تحتوي على مرفقات ضارة أو من
        خلال التنزيل غير المقصود الذي يحدث عندما يزور المستخدم موقع إلكتروني ضار
        عن غير قصد، وبعدها يتم تنزيل البرنامج الضار وتثبيته دون علم المستخدم
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          عواقب برامج طلب فدية:
        </span>
        <br />
        <span className={accordianDescriptionHeadingClass}>
          التكاليف الباهظة
        </span>
        <br />
        وفقًا لمجلة فوربس، كان متوسط تكاليف الإصلاح لشركة تعرضت لهجوم برنامج طلب
        فدية في عام 2019 أكثر من 761 ألف دولار. وفي عام 2020، تضاعف الرقم إلى
        1.85 مليون دولار. في الولايات المتحدة فقط، أنفق المتضررون من برامج طلب
        فدية ما متوسطه 2.09 مليون دولار على تكاليف الإصلاح.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          انخفاض فرص استرداد البيانات
        </span>
        <br />
        دفع فدية لا يضمن استرداد جميع البيانات. ذكرت مجلة فوربس أنه لم تتم إلا
        استعادة  <span dir="ltr">65%</span>فقط من البيانات في
        المتوسط.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          الضرر بسمعة العلامة التجارية
        </span>
        <br />
        يمكن أن تكون هجمات برامج طلب فدية مكلفة، ليس من الناحية المالية فحسب
        ولكن أيضًا في إنتاجية الشركات وانتهاكات بيانات العملاء. يمكن أن يكون
        للكشف عن البيانات الحساسة والشخصية ضررًا وخيمًا على سمعة الشركة.
        <br />
        <br />
      </div>
    ),
  },
  {
    title: "القانون العام لحماية البيانات (GDPR)",
    description: (
      <div>
        اللائحة العامة لحماية البيانات أو GDPR هي إطار قانوني يحتوي على مبادئ
        توجيهية لجمع بيانات المواطنين في الاتحاد الأوروبي. <br />
        <br />
        القانون العام لحماية البيانات (GDPR) متوفر باعتباره إطارًا للقوانين في
        جميع أنحاء القارة الأوروبية. يضمن الإطار القانوني تحسين سبل الحماية
        والحقوق للمواطنين في الاتحاد الأوروبي. ينظم القانون العام لحماية
        البيانات (GDPR) كيفية تعامل الشركات والمؤسسات الأخرى مع بيانات المستخدم
        والعملاء. سيفرض القانون العام لحماية البيانات (GDPR) غرامات شديدة على أي
        شخص ينتهك معايير الخصوصية والأمن وارده به.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          مبادئ حماية البيانات في القانون العام لحماية البيانات (GDPR){" "}
        </span>
        <br />
        يجب أن تكون المعالجة مشروعة وعادلة وشفافة فيما يخص موضوع البيانات. يجب
        أن تكون البيانات لغرض مشروع محدد لموضوع البيانات عند جمعها.
        <br />
        <br />
        ينبغي أن يكون للبيانات التي تحدد الهوية الشخصية حد للتخزين طالما كان ذلك
        ضروريًا للغرض المحدد.
        <br />
        يجب إجراء معالجة البيانات بطريقة تضمن الأمان والسلامة والسرية (على سبيل
        المثال باستخدام التشفير). يجب أن تتحلى جهة مراقبة البيانات بالمسؤولية عن
        قدرتها على إثبات الامتثال مع القانون العام لحماية البيانات (GDPR) لجميع
        هذه المبادئ.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          من ينطبق عليه القانون العام لحماية البيانات (GDPR)؟{" "}
        </span>
        <br />
        يجب على المؤسسات غير الأعضاء في الاتحاد الأوروبي الامتثال للقانون العام
        لحماية البيانات (GDPR) إذا كانت تقدم سلعًا وخدمات عبر الإنترنت. إذا لم
        تكن الشركة في الاتحاد الأوروبي ولكنها تلبي احتياجات العملاء به، فيجب أن
        تكون الشركة ممتثلة للقانون العام لحماية البيانات (GDPR).
        <br />
        إذا استخدمت مؤسسة ما أدوات الويب لتتبع ملفات تعريف الارتباط أو عناوين
        بروتوكول الإنترنت (IP) للأشخاص الذين يزورون الموقع من دول الاتحاد
        الأوروبي، يجب أن تكون هذه المؤسسة ممتثلة للقانون العام لحماية البيانات
        (GDPR).
      </div>
    ),
  },
];
