import React from "react";
import AnimateEachLetter from "../components/animation/AnimateEachLetter";
import "../styles/section-sub-title.css";
import AnimateOnDisplay from "./AnimateOnDisplay";

const SectionSubTitle = ({
  text,
  animationDelay,
  baseAnimationDelayInSec,
  animationDelayEachLetterInSec,
  charBgColor,
  className,
  animateEachLetterClass
}) => {
  const charBgColorClass = `bg-[${charBgColor}]`;
  const charMappingFunction = (character, index) => {
    const charAnimationDelay = animationDelay
      ? `${baseAnimationDelayInSec + index * animationDelayEachLetterInSec}s`
      : `${baseAnimationDelayInSec}s`;
    const textContent = character === " " ? "\u00A0" : character;
    return (
      <div dir="rtl" key={index} className={`${className} relative`}>
        <AnimateOnDisplay
          animationClass={"text-appear"}
          screenPercentHeightToStartAnimation={98}
          animateEveryTime={false}
        >
          <div
            className={`section-title-char-cover ${charBgColorClass}`}
            style={{ animationDelay: charAnimationDelay }}
          ></div>
          <div className="section-title-char">{textContent}</div>
        </AnimateOnDisplay>
      </div>
    );
  };
  return (
    <AnimateEachLetter
      className={`flex flex-wrap ${animateEachLetterClass || ""}`}
      charMappingFunction={charMappingFunction}
      text={text}
    />
  );
};

export default SectionSubTitle;

SectionSubTitle.defaultProps = {
  animationDelay: true,
  animationDelayEachLetterInSec: 0.1,
  charBgColor: "#fff",
  baseAnimationDelayInSec: 0,
};
