import React from "react";
import "../styles/letsChat.css";
import headphone from "../assets/svgs/let's-chat/headphone.svg";
import arrow from "../assets/svgs/let's-chat/arrow.svg";
import mouse from "../assets/svgs/let's-chat/mouse.svg";
import facebook from "../assets/icons/facebook.svg";
import linkedIn from "../assets/icons/linkedIn.svg";
import mail from "../assets/icons/mail.svg";
import {
  CONTACT_US_URL,
  EMAIL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  MAIL_URL,
  MOBILE,
  // MOBILE,
  PARENT_ADDRESS,
  SUB_ADDRESS,
} from "../data/Data";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
// import SectionSubTitle from "../reusable/SectionSubTitle";

const mediaLinks = [
  { image: facebook, url: FACEBOOK_URL },
  { image: linkedIn, url: LINKEDIN_URL },
  { image: mail, url: MAIL_URL },
];

const handleContactUsClicked = () => {
  window.open(CONTACT_US_URL, "_blank");
};

const mouseDiv = (
  <div className=" absolute flip-x aspect-w-1 aspect-h-1 top-[11.5%] -z-10 laptop:right-[16%]  laptop:block hidden">
    <img
      src={mouse}
      className="  laptop:pl-[25px]  pl-0 laptop:w-[100%] tablet:w-[80%] min-w-[20px] w-[45%]"
      alt=""
    />
  </div>
);

const arrowDiv = (
  <div className="aspect-w-1 aspect-h-1 laptop:mt-16 tablet:mt-14 mt-8">
    <img
      src={arrow}
      className="  laptop:pl-[25px]  pl-0 laptop:w-[100%] tablet:w-[80%] min-w-[20px] w-[60%]"
      alt=""
    />
  </div>
);

const togetherTextDiv = (
  <div className="text-[#111748] font-[900] text-right">معًا</div>
  // <SectionSubTitle
  //   baseAnimationDelayInSec={1}
  //   className="text-[#111748] font-[900]"
  //   text={"معاً"}
  //   animateEachLetterClass={"justify-end"}
  // />
);

const contactUsSection = (
  <div className="ml-[12vw]" dir="rtl">
    <div className=" laptop:text-[27px] tablet:text-[23px] text-[21px] whitespace-nowrap  tablet:mt-0 mt-[10px] mb-[30px]  mr-16">
      <span className=" text-[#11174899] ">لا تتردد في </span>
      <span
        onClick={handleContactUsClicked}
        className="underline text-[#FFC641] cursor-pointer"
      >
        التواصل معنا
      </span>
    </div>
    <div className="tablet:flex tablet:flex-row-reverse  tablet:space-x-[116px] tablet:space-y-[0px] space-y-[45px] ">
      {" "}
      <div className="tablet:text-[22px] text-[16px] text-[#11174899] leading-[31.92px] ">
        <p dir="ltr" className="text-right">
          {MOBILE}
        </p>
        <p>{EMAIL}</p>
      </div>
      <div
        dir="ltr"
        className="text-right tablet:text-[22px] pr-5 text-[16px] text-[#11174899] leading-[31.92px]"
      >
        <p>{SUB_ADDRESS}</p>
        <p>{PARENT_ADDRESS}</p>
      </div>
    </div>
    {/* 
    <div className="tablet:text-[22px] pr-5 text-[16px] text-[#11174899] leading-[31.92px]">
      <p>United Kingdom</p>
    </div>
    <div className="tablet:text-[22px] pr-5 text-[16px] text-[#11174899] leading-[31.92px]">
      <p>Dubai</p>
    </div> */}
  </div>
);

const mediaLinksSection = (
  <div className="space-y-[23px] mt-3 tablet:mt-0">
    {mediaLinks.map((mediaLink, i) => (
      <div key={i} className={`${i === 0 ? "mt-[110px]" : ""}`}>
        <a href={mediaLink.url} target="_blank" rel="noreferrer">
          <img src={mediaLink.image} alt="" />
        </a>
      </div>
    ))}
  </div>
);

const LetsChat = () => {
  return (
    <section
      id="letsChat"
      className="tablet:mt-[138px] mt-[69px] mb-[38px] relative"
    >
      <div className="flex justify-center laptop:block pr-16 laptop:pr-0">
        <div className="flex flex-row-reverse gap-[10vw] justify-center items-center laptop:space-x-14 uppercase leading-none section-title-font ">
          {/* <div id="borderDivLeft" className="laptop:block hidden">
            ORK
          </div> */}
          <div className="text-[#111748] font-[900] laptop:ml-0 ml-14 whitespace-nowrap">
            {" "}
            هيا نعمل
          </div>
          {/* <SectionSubTitle
            className="text-[#111748] font-[900]  laptop:ml-0 ml-14 whitespace-nowrap"
            text={"Let's Work"}
          /> */}
          <div className="aspect-w-1 aspect-h-1 ml-10">
            <img
              src={headphone}
              className=" laptop:w-[100%] tablet:w-[80%] min-w-[45px] w-[60%] "
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse justify-between">
        <div className="flex tablet:items-end items-start tablet:pt-0 mobile:pt-20 pt-5 mb-[34px] mr-[22px] tablet:mr-[66px] justify-between">
          {mediaLinksSection}
        </div>
        <div className="">
          <div className="flex flex-row-reverse items-base justify-center mobile:pr-0 tablet:pr-28 laptop:pr-0  mb-20 ">
            <div className="flip-x">{arrowDiv}</div>
            <div>
              <div className="laptop:ml-[4.5rem-]  uppercase section-title-font">
                {togetherTextDiv}
              </div>
              <AnimateOnDisplay
                animationClass={"paragraph-animation"}
                screenPercentHeightToStartAnimation={90}
                className={"opacity-0"}
                animateEveryTime={false}
              >
                {contactUsSection}
              </AnimateOnDisplay>
            </div>
            {/* <div
              id="borderDivRight"
              className="laptop:block hidden ml-12 section-title-font uppercase"
            >
              Tog
            </div> */}
          </div>
        </div>
      </div>
      {mouseDiv}
    </section>
  );
};
export default LetsChat;
