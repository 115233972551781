import React from "react";
import "../styles/softwareSolutions.css";
import SoftwareSolutionsCardsSection from "./SoftwareSolutionsCardsSection";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import SectionSubTitle from "../reusable/SectionSubTitle";

const SoftwareSolutions = () => {
  return (
    <section id="softwareSolutions">
      <div className=" laptop:py-[105px] tablet:py-[90px] mobile:py-[75px] py-[65px]  bg-[#ECECF0] laptop:flex laptop:flex-row-reverse  flex flex-col gap-[77px]">
        <div className="px-6 mobile:px-0 laptop:space-y-14 tablet:space-y-12 mobile:space-y-10 space-y-8 text-[#111748] leading-none ">
          <div className="space-y-10">
            <div className="flex justify-center flex-row-reverse w-full laptop:w-[35vw]">
              <div
                className={`-laptop:text-[108px] -tablet:text-[70px] -text-[40px] font-40-to-108 uppercase software-solution-section-title-animation`}
              >
                <div className="font-[300] text-right">خدمات</div>
                <div className="font-[900] text-left">البرامج</div>
              </div>
            </div>
            <AnimateOnDisplay
              animationClass={"paragraph-animation"}
              screenPercentHeightToStartAnimation={90}
              className={"opacity-0"}
              animateEveryTime={false}
            >
              {" "}
              <div dir="rtl" className="laptop:pr-16 tablet:pr-12 mobile:pr-8 opacity-60 laptop:text-[22px] tablet:text-[19px] text-[16px] text-[#111748bd]  leading-[25.06px] laptop:max-w-[30.818em] max-w-[60rem] text-right">
                PlanetBeyond هي شركة خدمات برامج ديناميكية وتطلعية متعهدة بتقديم
                حلول متطورة للعملاء في جميع أنحاء العالم. مع التركيز الجاد
                المتواصل على الابتكار والجودة ورضا العملاء، نقف كشريك موثوق به
                للشركات التي تسعى إلى الاستفادة من قوة التكنولوجيا لتحقيق نموها.
              </div>
            </AnimateOnDisplay>
          </div>
          <div className="space-y-4 laptop:pr-16 tablet:pr-12 mobile:pr-8">
            {/* <div className="font-[900] laptop:text-[39px] tablet:text-[34px] mobile:text-[27px] text-[21px] uppercase">
              Global Presence
            </div> */}
            <SectionSubTitle
              className={
                "font-[900] laptop:text-[39px]- tablet:text-[34px]- mobile:text-[27px]- text-[21px]- font-21-to-40 uppercase text-right"
              }
              text={"مدى الانتشار العالمي"}
              animateEachLetterClass={"justify-end"}
              charBgColor={"#ECECF0"}
            />
            <AnimateOnDisplay
              animationClass={"paragraph-animation"}
              screenPercentHeightToStartAnimation={90}
              className={"opacity-0"}
              animateEveryTime={false}
            >
              {" "}
              <div dir="rtl" className="opacity-60 text-right laptop:text-[22px] tablet:text-[19px] text-[16px] text-[#111748bd]  leading-[25.06px] laptop:max-w-[30.818em] max-w-[60rem]">
                من خلال الأثر العالمي، تلتزم PlanetBeyond بتقديم خدمة عملاء في
                مختلف الصناعات والجغرافيا المتنوعة. لقد اكتسبنا سمعة طيبة بصفتنا
                شريكًا مفضلًا للشركات التي تبحث عن حلول مبتكرة للبرامج بفضل
                التزامنا بالتميز ورضا العملاء.
              </div>
            </AnimateOnDisplay>
          </div>
        </div>
        <SoftwareSolutionsCardsSection />
      </div>
    </section>
  );
};

export default SoftwareSolutions;
