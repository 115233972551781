import React, { useEffect, useState } from "react";
import "../styles/mobileOperators.css";
import SectionSubTitle from "../reusable/SectionSubTitle";
import HorizontalBorderWithnDisplayAnimation from "../reusable/HorizontalBorderWithnDisplayAnimation";
//import mobileOperatorTitle from "../assets/svgs/mobile-operator-title.svg";
//import AnimateOnDisplay from "../reusable/AnimateOnDisplay";

const MobileOperators = () => {
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsTabletView(window.innerWidth <= 670);
    };

    checkScreenWidth();

    // Add event listener to update sTabletView state when window is resized
    window.addEventListener("resize", checkScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const solutionList = [
    {
      name: "حلول المراسلة",
      no: "١",
    },
    {
      name: "حلول التجوال",
      no: "٢",
    },
    {
      name: "حلول الشبكة الأساسية",
      no: "٣",
    },
    {
      name: "خدمات القيمة المضافة للمستهلكين والشركات",
      no: "٤",
    },
    {
      name: "منصات الاتصالات",
      no: "٥",
    },
    {
      name: "خدمة الدفع الإلكتروني المباشر (DCB)",
      no: "٦",
    },
    {
      name: "الخدمات المُدارة",
      no: "٧",
    },
    {
      name: "حركة نقل البيانات وإدارة السياسة",
      no: "٨",
    },
  ];
  return (
    <section id="mobileOperators" className="bg-[#111748]">
      {/* <AnimateOnDisplay
        className={
          "mx-5 tablet:mx-8 laptop:mx-16 pt-20 laptop:pt-24 mb-8 laptop:mb-16 "
        }
        animationClass={"snap-in"}
      >
        <img src={mobileOperatorTitle} alt="Mobile Operator" />
      </AnimateOnDisplay> */}
      <div className="flex justify-end mx-5 tablet:mx-8 laptop:mx-16 pt-20 laptop:pt-24 mb-8 laptop:mb-16">
        <div id="mobile-operators-title" className="font-40-to-108 uppercase">
          <div className="text-[#FFFFFF] text-right font-[300]">شركات</div>
          <div className="text-left font-[900] text-[#FFC641]">الهاتف المحمول</div>
        </div>
      </div>
      <div className="grid laptop:grid-cols-2 grid-rtl border-t-2- border-t-[#FFFFFF33]-">
        {solutionList.map((solutionItem, i) => (
          <div key={i} className="laptop:even:border-r-[1px] border-[#FFFFFF33]">
            <HorizontalBorderWithnDisplayAnimation
              directionRightToLeft={!isTabletView && i % 2 === 0 ? false : true}
              borderColor={"#FFFFFF33"}
            />
            <div className="px-5 tablet:px-[3em] py-6 mobile:py-8 tablet:py-10 laptop:py-12 text-[#fff] border-b-[1px]- laptop:odd:border-r-[1px]- border-[#FFFFFF33]-">
              {/* <p className="font-[900] text-[21px] laptop:text-[25px] xl:text-[30px] desktop:text-[40px] uppercase">
              {solutionItem.name}
            </p> */}
              <SectionSubTitle
                className="font-[900] text-[21px]- laptop:text-[25px]- xl:text-[30px]- desktop:text-[40px]- font-21-to-40 uppercase"
                text={solutionItem.name}
                charBgColor={"#111748"}
              />
              <p className=" font-[400] text-[#ffffff33]">{solutionItem.no}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MobileOperators;
