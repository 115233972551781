import React, { useEffect, useId, useState } from "react";
import elementIsBeingDisplayed from "../functions/elementIsBeingDisplayed";

const AnimateOnDisplay = ({
  id,
  animationClass,
  className,
  children,
  animateEveryTime,
  screenPercentHeightToStartAnimation,
}) => {
  const elementId = useId();
  const trackigId = id || elementId;
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const elementIsVisible = elementIsBeingDisplayed(
        trackigId,
        screenPercentHeightToStartAnimation
      );
      if (animateEveryTime) {
        setIsVisible(elementIsVisible);
      } else if (!isVisible) {
        setIsVisible(elementIsVisible);
        if (elementIsVisible) {
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };
    // Attach event listener for scrolling
    window.addEventListener("scroll", handleScroll);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    animateEveryTime,
    elementId,
    isVisible,
    screenPercentHeightToStartAnimation,
    trackigId,
  ]);
  return (
    <div
      id={elementId}
      className={` ${
        isVisible ? animationClass : "noAnimationClass"
      } ${className}`}
      dir="rtl"
    >
      {children}
    </div>
  );
};

export default AnimateOnDisplay;
AnimateOnDisplay.defaultProps = {
  className: "",
  animationClass: "",
  animateEveryTime: true,
  screenPercentHeightToStartAnimation: 100,
};
