import React from "react";
import "../styles/resourceAugmentation.css";
import productivityBoostLogo from "../assets/svgs/resource-augmentation/productivity-boost.svg";
import techAdvancmentsLogo from "../assets/svgs/resource-augmentation/tech-advancments.svg";
import staffingFlexibilityLogo from "../assets/svgs/resource-augmentation/staffing-flexibility.svg";
import chipsLogo from "../assets/svgs/resource-augmentation/chips.svg";
import CustomizedTooltips from "./CustomizedTooltips";
import SectionTitle from "../reusable/SectionTitle";
import SectionSubTitle from "../reusable/SectionSubTitle";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";

const ResourceAugmentation = () => {
  return (
    <section
      id="resourceAugmentation"
      className="py-20 tablet:py-32 laptop:pt-[163px] laptop:pb-[251px] bg-[#111748] text-[#fff]"
    >
      <div className="flex justify-center text-right tablet:text-center">
        <div className="mx-5 space-y-5 tablet:mx-[265px] leading-none">
          {/* <AnimateOnDisplay
            className={`uppercase text-[40px] tablet:text-[63px] laptop:text-[85px] desktop:text-[108px] font-[300]`}
            animationClass={"snap-in"}
          >
            <div>resource</div>
            <div className="font-[900]">augmentation</div>
          </AnimateOnDisplay> */}
          <SectionTitle
            className={`uppercase text-[40px] leading-snug tablet:text-[63px] laptop:text-[85px] desktop:text-[108px] font-[300]`}
            firstChild={<div>زيادة</div>}
            secondChild={<div className="font-[900]">الموارد</div>}
            viewPortStartPointFromBottom={0}
            viewPortEndPointFromBottom={50}
            firstChildTranslateAtStart={window.innerWidth * 0.3}
          />
          <AnimateOnDisplay
            animationClass={"paragraph-animation"}
            screenPercentHeightToStartAnimation={90}
            className={"opacity-0"}
            animateEveryTime={false}
          >
            {" "}
            <div
              dir="rtl"
              className="text-[#FFFFFF99] pt-1 text-[16px] tablet:text-[18px] laptop:text-[20px] desktop:text-[22px] mobile:leading-[34.82px] leading-[23.22px]"
            >
              في العرض الحالي سريع الخطى ودائم التطور، لا يتطلب البقاء في
              المنافسة الابتكار فحسب، بل يتطلب أيضًا التخصيص الاستراتيجي
              للموارد. نحن نقدم حلاً يتجاوز الزيادة التقليدية للموارد. تخيل
              إطلاق العنان للإمكانات الكاملة الخاصة بمؤسستك من خلال تعزيز أصولك
              البشرية والمالية والتكنولوجية بشكل استراتيجي.
            </div>
          </AnimateOnDisplay>
        </div>
      </div>
      <div className="px-[22px] tablet:px-[87px] laptop:px-[152px] desktop:px-[217px] mt-[61px] tablet:mt-[78px] space-y-[52px] relative bg-gray-600-">
        {/* Icons Div */}
        <div className="absolute w-full h-full">
          <div className="relative h-full w-full">
            <div className="absolute top-[15%] tablet:top-0 left-[10%] tablet:left-[60%] laptop:left-[37%] desktop:left-[32%] translate-y-[30%] laptop:translate-y-[-20%] translate-x-[-50%]">
              <img
                className="beat-anim w-[84px] mobile:w-[100px] tablet:w-[129px]"
                src={productivityBoostLogo}
                alt="img"
              />
            </div>
            <div className="absolute right-[12%] tablet:right-[80%] laptop:right-[95%] top-[43%] mobile:top-[46%] tablet:top-[30%] laptop:top-[47%] translate-y-[-50%] tablet:translate-y-[-50%]">
              <img
                className="beat-anim w-[72px] mobile:w-[90px] tablet:w-[118px]"
                src={techAdvancmentsLogo}
                alt="img"
              />
            </div>
            <div className="absolute top-[82%] mobile:top-[90%] tablet:top-[57%] laptop:top-[49%] right-[12%] tablet:right-[23%] translate-y-[-50%]">
              <img
                className="beat-anim w-[118px] mobile:w-[150px] laptop:w-[200px]"
                src={staffingFlexibilityLogo}
                alt="img"
              />
            </div>
            <div className="absolute bottom-[30%] mobile:bottom-[27%] tablet:bottom-[20%] laptop:bottom-[5%] left-[11%] tablet:left-[10%] laptop:left-[38%] translate-x-[-50%] ">
              <img
                className="mt-[50px] beat-anim w-[80px]  tablet:w-[100px] laptop:w-[130px]"
                src={chipsLogo}
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="grid laptop:grid-cols-2 mt-[17px] space-y-16 tablet:space-y-[83px] laptop:mr-28">
          <SubSection
            title={"تعزيز الإنتاجية:"}
            description={
              <>
                <AnimateOnDisplay
                  animationClass={"snap-in"}
                  className={"opacity-0"}
                  animateEveryTime={false}
                >
                  {" "}
                  <span className="inline">
                    يتيح لك حلنا توسيع نطاق قوتك العاملة والهيكل الأساسي
                    التكنولوجي بما يتوافق مع احتياجات عملك.
                  </span>
                </AnimateOnDisplay>
              </>
            }
            note={
              "يتيح لك حلنا توسيع نطاق قوتك العاملة والهيكل الأساسي التكنولوجي بما يتوافق مع احتياجات عملك. سواء كنت تواجه زيادة كبيرة في الطلب أو تستعد لمشروع جديد، فإن حلنا يتيح لك تحسين العمليات وتعزيز الإنتاجية الإجمالية."
            }
          />
          <div className=" flex justify-end bg-cyan-600- w-full">
            <SubSection
              className={""}
              title={"مرونة توفير موظفين:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="inline">
                      تخلص من قيود القوى العاملة للأبد. مع زيادة الموارد، يمكنك
                      بسرعة إلحاق المهنيين المتخصصين للمشاريع قصيرة الأجل أو
                      لفترات ضغط العمل، ما يضمن أن يكون لديك الموهبة المناسبة
                      عندما تكون في أمس الحاجة إليها.
                    </span>{" "}
                  </AnimateOnDisplay>
                </>
              }
              note={
                "تخلص من قيود القوى العاملة للأبد. مع زيادة الموارد، يمكنك بسرعة إلحاق المهنيين المتخصصين للمشاريع قصيرة الأجل أو لفترات ضغط العمل، ما يضمن أن يكون لديك الموهبة المناسبة عندما تكون في أمس الحاجة إليها. تتيح لك هذه المرونة التكيف بسرعة مع تغييرات السوق واغتنام الفرص دون عبء الالتزامات طويلة الأجل. "
              }
            />
          </div>
        </div>
        <div className="grid tablet:grid-cols-2">
          <div className="flex laptop:justify-end">
            <SubSection
              className={"mt-6"}
              title={"التطورات التكنولوجية:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="inline">
                      اعتمد أحدث التقنيات دون صعوبات الاستثمارات الكبرى. يتيح لك
                      نموذجنا دمج الحلول المتطورة بسلاسة في عملياتك.
                    </span>
                  </AnimateOnDisplay>
                </>
              }
              note={
                "اعتمد أحدث التقنيات دون صعوبات الاستثمارات الكبرى. يتيح لك نموذجنا دمج الحلول المتطورة بسلاسة في عملياتك. كن في المقدمة وحافظ على عملك في المستقبل دون المساس بميزانيتك."
              }
            />
          </div>
        </div>
        <div className="grid tablet:grid-cols-2  laptop:mr-28">
          <div className="order-2 tablet:order-1">
            <SubSection
              className="mt-16 tablet:mt-[150px]"
              title={"فعالية التكلفة:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="inline">
                      تقديم بديل فعال من حيث التكلفة للتوظيف التقليدي
                      والاستثمارات التكنولوجية.
                    </span>
                  </AnimateOnDisplay>
                </>
              }
              note={
                "تقديم بديل فعال من حيث التكلفة للتوظيف التقليدي والاستثمارات التكنولوجية. من خلال الدفع مقابل الموارد فقط عند الحاجة، فإنك تتخلص من التكاليف العامة غير الضرورية وتزيد من عائد استثمارك. إنه نهج أكثر ذكاءً وسلاسة لإدارة الموارد. "
              }
            />
          </div>
          <div className="flex justify-end order-1 tablet:order-2">
            <SubSection
              title={"التكيف السريع:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="inline">
                      تتسم القدرة على التكيف بالأهمية في بيئة الأعمال
                      الديناميكية اليوم.
                    </span>
                  </AnimateOnDisplay>
                </>
              }
              note={
                "تتسم القدرة على التكيف بالأهمية في بيئة الأعمال الديناميكية اليوم. توفر زيادة الموارد سرعة الزيادة أو النقصان بسرعة، ما يضمن مواكبتك دائمًا لمتطلبات السوق. هذا النهج الذكي يوجّه مؤسستك نحو النمو والنجاح المستدامين."
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResourceAugmentation;

const SubSection = ({ title, description, className, note }) => {
  return (
    <>
      <div
        dir="rtl"
        className={`w-[257px] mobile:w-[400px] tablet:w-[450px] desktop:w-[499px] max-h-[167px] saoce-y-5 ${className}`}
      >
        {/* <div className="uppercase font-[900] text-[21px] tablet:text-[27px] laptop:text-[33px] desktop:text-[39px] leading-normal">
          {title}
        </div> */}
        <div className="uppercase font-[900] text-[20px] tablet:text-[27px] laptop:text-[33px] desktop:text-[39px] leading-normal">
          <SectionSubTitle text={title} charBgColor={"#111748"} />
        </div>
        <div className="text-[16px] tablet:text-[18px] laptop:text-[20px] desktop:text-[22px] text-[#FFFFFF99]">
          {description}...
          <CustomizedTooltips note={<span dir="rtl">{note}</span>} />
        </div>
      </div>
    </>
  );
};
