import React, { useState } from "react";
import "../styles/enterpriseSolutions.css";
//importing Images for services
import image01 from "../assets/svgs/enterprise-solution-svgs/image01.jpg";
import image02 from "../assets/svgs/enterprise-solution-svgs/image02.jpg";
import image03 from "../assets/svgs/enterprise-solution-svgs/image03.jpg";
import image04 from "../assets/svgs/enterprise-solution-svgs/image04.jpg";
import image05 from "../assets/svgs/enterprise-solution-svgs/image05.jpg";
import image06 from "../assets/svgs/enterprise-solution-svgs/image06.jpg";
import image07 from "../assets/svgs/enterprise-solution-svgs/image07.jpg";
import image08 from "../assets/svgs/enterprise-solution-svgs/image08.jpg";
import image09 from "../assets/svgs/enterprise-solution-svgs/image09.jpg";
import image10 from "../assets/svgs/enterprise-solution-svgs/image10.jpg";
import image11 from "../assets/svgs/enterprise-solution-svgs/image11.jpg";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import ShakingBtn from "./animation/ShakingBtn";
import ImageWithAnimation from "./animation/ImageWithAnimation";
import SectionSubTitle from "../reusable/SectionSubTitle";
import HorizontalBorderWithnDisplayAnimation from "../reusable/HorizontalBorderWithnDisplayAnimation";

const EnterpriseSolutions = () => {
  const noOfComponentsToShowWhenCompressed = 4;
  const [isExpanded, setIsExpanded] = useState(false);
  const expandClicked = () => {
    setIsExpanded(true);
  };
  const compressClicked = () => {
    setIsExpanded(false);

    const element = document.getElementById(
      "lastEnterpriseSolutionWhenCompressed"
    );
    const rect = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const targetY = rect.top + scrollTop;

    window.scrollTo({
      top: targetY,
      behavior: "smooth",
    });
  };

  return (
    <section id="enterpriseSolutions" className="bg-[#fff] text-[#111748]">
      <div className="pt-[5.1875em] pb-8 mobile:pb-12 tablet:pb-16 laptop:pb-32 space-y-3 tablet:space-y-6 laptop:space-y-9 desktop:space-y-12">
        <AnimateOnDisplay
          animationClass={"snap-in-"}
          className={`flex justify-center items-center`}
        >
          <div
            className={`-text-[40px] -tablet:text-[62.67px] -laptop:text-[85.33px] -desktop:text-[108px] font-40-to-108 enterprise-solution-section-title-animation uppercase leading-snug`}
          >
            <div className="text-[#111748] text-right font-[300] bg-yellow-600-">
              حلول
            </div>
            <div className="text-[#FFC641] text-left font-[900] bg-green-600-">
              المؤسسات
            </div>
          </div>
        </AnimateOnDisplay>
        <AnimateOnDisplay
          animationClass={"paragraph-animation"}
          screenPercentHeightToStartAnimation={90}
          className={"opacity-0"}
          animateEveryTime={false}
        >
          <p dir="rtl" className="text-[16px] tablet:text-[22px] laptop:text-[28px] desktop:text-[34px] font-[200] text-center mx-[10%] laptop:mx-[13.33%]">
            نلتزم في PlanetBeyond بتزويد الشركات بالأدوات التي تلزمها لتحقيق
            النجاح في عرض رقمي سريع التطور. تهدف حلول المؤسسات لدينا إلى التعامل
            مع التحديات والفرص الخاصة التي تواجهها المؤسسات في سعيها وراء
            التميز.
            {/* في بلانىت بيوند،
            <span className="font-[500]">
              {" "}
              نحن ملتزمون بتزويد الشركات بالأدوات التي تحتاجها لتزدهر
            </span>{" "}
            في مشهد رقمي سريع التطور. تم تصميم حلول المؤسسات الخاصة بنا لمعالجة
            التحديات والفرص الفريدة التي تواجهها
            <span className="font-[500]">
              المؤسسات في سعيها لتحقيق التميز.
            </span>{" "} */}
          </p>
        </AnimateOnDisplay>
      </div>
      <div className="border-t-[1px]- border-[#11174833] text-[#111748]">
        <HorizontalBorderWithnDisplayAnimation borderColor={"#11174833"} />
        {servicesList.map(
          (service, i) =>
            (isExpanded || i < noOfComponentsToShowWhenCompressed) && (
              <div key={i}>
                <div
                  className="px-[5vw] tablet:px-16 py-[31px] min-h-[212px] flex flex-col-reverse gap-[21px] tablet:flex-row-reverse items-center justify-between border-b-[1px]- border-[#11174833]-"
                  id={
                    i === noOfComponentsToShowWhenCompressed - 1
                      ? "lastEnterpriseSolutionWhenCompressed"
                      : `enterpriseSolution${i}`
                  }
                >
                  <div className="flex justify-center items-center w-full ">
                    <div className="flex flex-row-reverse justify-center tablet:justify-normal items-baseline ">
                      <div className="w-[37px] text-center tablet:w-[5.625em]">
                        {service.no}
                      </div>
                      <div className="max-w-[80%] text-right">
                        {/* <p className="font-[900] text-[21px] tablet:text-[27.33px] laptop:text-[33.67px] desktop:text-[40px] uppercase leading-snug">
                        {service.title}
                      </p> */}
                        <SectionSubTitle
                          className={
                            "font-[900] text-right text-[21px] tablet:text-[27.33px] laptop:text-[33.67px] desktop:text-[40px] uppercase leading-snug"
                          }
                          animateEachLetterClass={"justify-end"}
                          text={service.title}
                        />
                        <AnimateOnDisplay
                          animationClass={"paragraph-animation"}
                          screenPercentHeightToStartAnimation={90}
                          className={"opacity-0"}
                          animateEveryTime={false}
                        >
                          {" "}
                          <p
                            dir="rtl"
                            className="font-[300] text-[16px] tablet:text-[22px] tablet:hidden"
                          >
                            <ExpandableText
                              noOfCharsWhenCompressed={134}
                              text={service.description}
                            />
                          </p>
                        </AnimateOnDisplay>
                        <AnimateOnDisplay
                          animationClass={"paragraph-animation"}
                          screenPercentHeightToStartAnimation={90}
                          className={"opacity-0"}
                          animateEveryTime={false}
                        >
                          {" "}
                          <p dir="rtl" className="font-[300] text-[21px] tablet:text-[22px] hidden tablet:block leading-snug">
                            {service.description}
                          </p>
                        </AnimateOnDisplay>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center item-center w-full tablet:w-auto">
                    <div
                      className="min-w-[90vw] max-w-[90vw] min-h-[239px]  
                    mobile:min-w-[450px] mobile:min-h-[300px] mobile:max-w-[450px] mobile:max-h-[300px] 
                tablet:min-w-[17.5em] tablet:min-h-[10.9375em] tablet:max-w-[17.5em] tablet:max-h-[10.9375em] "
                    >
                      <ImageWithAnimation
                        src={service.img}
                        animatingDivHeight={"400px"}
                      />
                      {/* <img
                      className="w-full h-full object-contain"
                      src={service.img}
                      alt="not found"
                    /> */}
                    </div>
                  </div>
                </div>
                <HorizontalBorderWithnDisplayAnimation
                  borderColor={"#11174833"}
                />
              </div>
            )
        )}
      </div>
      <div className="pt-[75px] pb-[156px] flex justify-center ">
        {isExpanded ? (
          <ShakingBtn>
            {" "}
            <button onClick={compressClicked} className={expandBtnClass}>
              طي الكل
            </button>
          </ShakingBtn>
        ) : (
          <ShakingBtn>
            {" "}
            <button onClick={expandClicked} className={expandBtnClass}>
              توسيع الكل
            </button>
          </ShakingBtn>
        )}
      </div>
    </section>
  );
};

const ExpandableText = ({ noOfCharsWhenCompressed, text }) => {
  const [isCompressed, setIsCompressed] = useState(true);
  const seeMoreClicked = () => {
    setIsCompressed(false);
  };
  const seeLessClicked = () => {
    setIsCompressed(true);
  };
  const textToShow = isCompressed
    ? text.slice(0, noOfCharsWhenCompressed) + "..."
    : text + ". ";
  const btn = isCompressed ? (
    <button className="text-[#111748] font-[500]" onClick={seeMoreClicked}>
      اقرأ المزيد
    </button>
  ) : (
    <button className="text-[#111748] font-[500]" onClick={seeLessClicked}>
      اقرأ أقل
    </button>
  );
  return (
    <>
      {textToShow}
      {btn}
    </>
  );
};

export default EnterpriseSolutions;

const expandBtnClass =
  "border-[1px] border-[#11174833] rounded-[52px] text-[1em] text-center content-center px-7 py-3";

const servicesList = [
  {
    no: "١",
    title: "المكالمات والرسائل النصية القصيرة للشركات",
    description:
      "تهدف خدمات المكالمات والرسائل القصيرة للشركات المقدمة من Planet Beyond إلى تلبية احتياجات الاتصالات التي تلزم الشركات والمؤسسات، وتوفر الحلول المخصصة للشركات من أجل تلبية متطلباتها",
    img: image01,
  },
  {
    no: "٢",
    title: "منصة التسويق عبر الهاتف المحمول",
    description:
      "مجموعة شاملة من الأدوات والحلول لمساعدة الشركات على الوصول إلى عملائها والتفاعل معهم بشكل فعال من خلال الأجهزة المحمولة.       تلبي هذه المنصات الجوانب المختلفة للتسويق عبر الأجهزة المحمولة، بدءًا من التسويق عبر الرسائل النصية القصيرة، الذي يتيح المراسلات الموجهة والمخصصة، وحتى التسويق عبر تطبيقات الهواتف المحمولة، الذي يساعد على جذب المستخدم وتفاعله من خلال الإعلانات الموجهة والإشعارات اللحظية",
    img: image02,
  },
  {
    no: "٣",
    title: "تحليلات البيانات",
    description:
      "توفر أدواتنا لتحليل البيانات المتقدمة إحصاءات قيّمة حول سلوك العملاء وتوجهات السوق والعمليات التجارية، ما يتيح للشركات اتخاذ قرارات قائمة على الوعي تزيد من مستوى النمو والكفاءة. سواء كان ذلك يتعلق بتحليل البيانات الديموغرافية للعملاء أو التنبؤ بتوجهات السوق أو تحسين إدارة سلسلة التوريد، فإن خدماتنا لتحليلات البيانات تعمل على تزويد الشركات بالأدوات التي تلزمها لتحقيق النجاح في عالم اليوم الذي يعتمد على البيانات.",
    img: image03,
  },
  {
    no: "٤",
    title: "حل إدارة الولاء",
    description:
      "يتيح حلنا للشركات زيادة معدل الاحتفاظ بالعملاء ورفع نسبة عمليات الشراء المتكررة وتعزيز مستوى رضا العملاء بشكل عام، بفضل ميزات مثل برامج الولاء القابلة للتخصيص وتقسيم العملاء إلى شرائح والمكافآت المخصصة.",
    img: image04,
  },
  {
    no: "٥",
    title: "تكنولوجيا الإعلان والاتصالات متعددة القنوات",
    description:
      "تتضمن مجموعة خدماتنا تكنولوجيا متقدمة للإعلان تتيح للشركات توجيه إعلاناتها للجمهور المناسب في الوقت المناسب وعلى المنصة المناسبة. تساعد حلولنا لتكنولوجيا الإعلان الشركات على زيادة عائد استثمارها الخاص بالإعلان وتعزيز مستوى نموها، بفضل ميزات مثل الإعلان البرنامجي وإنشاء الإعلانات الديناميكية والتتبع عبر القنوات.",
    img: image05,
  },
  {
    no: "٦",

    title: "التطور طويل الأمد (LTE) الخاص",
    description:
      "توفر شبكات LTE لدينا موثوقية وسعة وتغطية فائقة مقارنةً بشبكات Wi-Fi التقليدية أو الشبكات الخلوية العامة، وهو ما يجعلها مثالية لمجموعة كبيرة من التطبيقات، بما في ذلك إنترنت الأشياء (IoT) للقطاع الصناعي والمدن الذكية والمكاتب البعيدة وغير ذلك الكثير.",
    img: image06,
  },
  {
    no: "٧",

    title: "حل اعرف عميلك إلكترونيًا",
    description:
      "يُحدث حل اعرف عميلك إلكترونيًا (E-KYC) ثورة في عملية تأهيل العملاء من خلال إتاحة الفرصة للشركات للتحقق من هويات عملائها بشكل رقمي وسريع وآمن. يضمن حلنا الامتثال للمتطلبات التنظيمية وفي الوقت نفسه تعزيز تجربة العميل، بفضل ميزات مثل التحقق الآلي من الهوية والتعرف على الوجه ومصادقة المستندات.",
    img: image07,
  },
  {
    no: "٨",

    title: "وحدة التحكم في الشكل المحدد لجلسات اتصالات الوسائط المتعددة (SBC)",
    description:
      "تهدف وحدات SBC لدينا إلى تعزيز الأمان والموثوقية وقابلية التشغيل البيني لخدمات الصوت والفيديو والمراسلات عبر شبكات بروتوكول الإنترنت (IP). تضمن حلول SBC لدينا الاتصال الآمن والسلس بين نطاقات الشبكة المختلفة، بفضل ميزات مثل عبور جدار الحماية وعبور ترجمة عنوان الشبكة (NAT) والتشفير وضوابط جودة الخدمة (QoS).",
    img: image08,
  },
  {
    no: "٩",
    title: "نمو الأنشطة التجارية عبر الإنترنت",
    description:
      "تهدف خدمات نمو الأنشطة التجارية عبر الإنترنت من Planet Beyond إلى مساعدة الشركات على تحقيق النجاح في الاقتصاد الرقمي اليوم. تهدف خدماتنا إلى تلبية الاحتياجات المتنوعة للشركات في مختلف الصناعات، بدءًا من تأسيس مدى انتشار قوي عبر الإنترنت حتى تنفيذ استراتيجيات فعالة في مجال التسويق الرقمي.تشمل حلولنا تطوير المواقع الإلكترونية وتحسينها وتحسين محرك البحث (SEO) والتسويق عبر وسائل التواصل الاجتماعي والتسويق بالمحتوى وتسويق عبر البريد الإلكتروني وغير ذلك الكثير.",
    img: image09,
  },
  {
    no: "١٠",
    title: "حل مركز الاتصال",
    description:
      "يتيح حل مركز الاتصال لدينا للشركات تعزيز أداء خدمة العملاء بشكل عام. يمنح حلنا للشركات الفرصة لإجراء تجارب استثنائية للعملاء وتحقيق نتائج إيجابية، بفضل ميزات مثل توجيه الاتصال الذكي والاستجابة الصوتية التفاعلية (IVR) وتسجيل المكالمات والتحليلات اللحظية.",
    img: image10,
  },
  {
    no: "١١",
    title: "أداة الحماية من الرسائل غير المرغوب فيها",
    description:
      "من خلال تنفيذ حل أداة الحماية من الرسائل غير المرغوب فيها من Planet Beyond، يمكن للشركات حماية رسائل البريد الإلكتروني والحد من مخاطر انتهاكات البيانات والحفاظ على سمعة علامتها التجارية. يضمن حلنا أن رسائل بريدك الإلكتروني آمنة وخاضعة للحماية من التهديدات الإلكترونية، بفضل ميزات مثل معلومات التهديد اللحظية وعوامل التصفية القابلة للتخصيص وإجراءات الاستجابة الآلية.",
    img: image11,
  },
];
