// Import React library along with useEffect and useState hooks
import React from "react";

// Import styles specific to the PaymentSolutions component
import "../styles/paymentSolutions.css";

// Import the paymentSolution image
import paymentSolution from "../assets/svgs/paymentsolutions.jpg";
import SectionTitle from "../reusable/SectionTitle";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import ImageWithAnimation from "./animation/ImageWithAnimation";
import SectionSubTitle from "../reusable/SectionSubTitle";

// Define the PaymentSolutions functional component
const PaymentSolutions = () => {
  /*useEffect(() => {
    const handleScroll = () => {
      const elementIsInViewPortConst = elementIsInViewPort(
        "payment-solutions-title", 50, 30
      );
      console.log(elementIsInViewPortConst);
    };
    // Attach event listener for scrolling
    window.addEventListener("scroll", handleScroll);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  */

  // Return the JSX structure for the PaymentSolutions component
  return (
    <section
      id="paymentSolutions"
      className="tablet:py-[110px] py-[70px] min-h-[100vh] px-5 tablet:px-[67px]"
    >
      {/* <div className="flex justify-center">
        <div
          id="payment-solutions-title"
          className={`text-center font-40-to-108`}
        >
          <p className="leading-none text-left font-[300] text-[#111748]">
            PAYMENT
          </p>
          <p className="text-right font-[900] leading-none text-[#111748]">
            SOLUTIONS
          </p>
        </div>
      </div> */}
      <SectionTitle
        className={"text-center font-40-to-108 bg-red-500-"}
        firstChild={
          <p className="leading-none text-left- font-[300] text-[#111748] bg-blue-400-">
            حلول{" "}
          </p>
        }
        secondChild={
          <p className="text-right- font-[900] leading-none text-[#111748] bg-green-500-">
            المدفوعات
          </p>
        }
        viewPortStartPointFromBottom={0}
        viewPortEndPointFromBottom={50}
        firstChildTranslateAtStart={window.innerWidth * 0.3}
      />
      <div className="tablet:flex tablet:flex-row flex flex-col items-center tablet:space-x-20 laptop:mt-[120px] tablet:mt-[80px] mt-[39px]">
        {/* Image for the Payment Solutions section */}
        <div className="w-[90vw] tablet:w-[50%]">
          {/* <img className="w-full h-full" src={paymentSolution} alt="" /> */}
          <ImageWithAnimation src={paymentSolution} />
        </div>
        {/* Text content for the Payment Solutions section */}
        <div className="space-y-[36px] tablet:w-[50%] w-auto mt-[32px] laptop:mt-[0px]">
          {/* Heading for the Payment Solutions section */}
          {/* <p className="text-[#111748] font-[900] uppercase laptop:text-[48px] tablet:text-[38px] text-[27px]">
            Payment APIs for airtime top-ups made simple!
          </p> */}
          <SectionSubTitle
            className={
              "text-[#111748] font-[900] text-right uppercase laptop:text-[48px] tablet:text-[38px] text-[27px]"
            }
            animateEachLetterClass={"justify-end"}
            text={
              "أصبحت واجهات برمجة التطبيقات للمدفوعات الخاصة بعمليات إعادة شحن الهاتف المحمول بسيطة!"
            }
          />
          <div>
            {/* Subheading and description for the Payment Solutions section */}
            <p dir="rtl" className="text-[#111748] font-[500] text-right laptop:text-[27px] tablet:text-[23px] text-[20px]">
              مكّن تطبيقاتك ومواقعك الإلكترونية من إرسال عملية إعادة الشحن
              للهاتف المحمول في جميع أنحاء العالم.
            </p>
            <AnimateOnDisplay
              animationClass={"paragraph-animation"}
              screenPercentHeightToStartAnimation={90}
              className={"opacity-0"}
              animateEveryTime={false}
            >
              {" "}
              <p dir="rtl" className="text-[#111748] font-[300] text-right laptop:text-[22px] tablet:text-[19px] text-[16px]">
                سواء كنت شركة ناشئة أو شركة كبيرة، يمكنك استخدام بوابة المدفوعات
                لدينا لإرسال عمليات إعادة الشحن للهاتف المحمول على مستوى العالم.
                نحن نقدم نظام فواتير واضحًا حسب الاستخدام، بدون عقود طويلة الأجل
                أو رسوم مستترة.
              </p>
            </AnimateOnDisplay>
          </div>
        </div>
      </div>
    </section>
  );
};

// Export the PaymentSolutions component as the default export
export default PaymentSolutions;
