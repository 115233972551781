import React from "react";
const AnimateEachLetter = ({
  text,
  charMappingFunction,
  className,
  baseAnimationDelay,
  animationLetterDelay,
  animationClass,
  animateNow,
  charClass,
  charDisplay,
}) => {
  const isArabicLanguage = true;
  const defaultMapping = (character, index) => (
    <div
      key={index}
      className={`${charClass} ${animateNow ? animationClass : ""}`}
      style={{
        display: charDisplay,
        animationDelay:
          `${baseAnimationDelay + animationLetterDelay * index}s` || "0s",
      }}
    >
      {character === " " ? "\u00A0" : character}
    </div>
  );
  if (charMappingFunction) {
    if (isArabicLanguage) {
      return <div className={className}>{charMappingFunction(text, 0)}</div>;
    }
    return (
      <div className={className}>{text.split("").map(charMappingFunction)}</div>
    );
  } else {
    if (isArabicLanguage) {
      return defaultMapping(text, 0);
    }
    return (
      <div className={className}>{text.split("").map(defaultMapping)}</div>
    );
  }
};

export default AnimateEachLetter;

AnimateEachLetter.defaultProps = {
  className: "",
  subParentClass: "",
  animationLetterDelay: 0.05,
  baseAnimationDelay: 0,
  animateNow: true,
  animationClass: "",
  charClass: "",
  charDisplay: "inline-block",
};
