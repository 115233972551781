// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/svgs/cyberSecurityAr.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/icons/bulletsBlue.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/svgs/cyberSecurityAr-sm.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cyberParent {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffc641 92.71%),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___});

  background-size: cover;
  background-position: center;
}

.listItemStyle {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-position: 100% 0.4em;
  padding-right: 1ch;
}

@media only screen and (max-width: 670px) {
  .cyberParent {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #ffc641 92.71%
      ),
      url(${___CSS_LOADER_URL_REPLACEMENT_2___});

    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    /* background-position: 100% 0%; */
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/cyberSecurity.css"],"names":[],"mappings":"AAAA;EACE;2CAC2C;;EAE3C,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,yDAAsD;EACtD,4BAA4B;EAC5B,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE;IACE;;;;;6CAK8C;;IAE9C,0BAA0B;IAC1B,4BAA4B;IAC5B,4BAA4B;IAC5B,kCAAkC;EACpC;AACF","sourcesContent":[".cyberParent {\n  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffc641 92.71%),\n    url(\"../assets/svgs/cyberSecurityAr.svg\");\n\n  background-size: cover;\n  background-position: center;\n}\n\n.listItemStyle {\n  background-image: url(../assets/icons/bulletsBlue.svg);\n  background-repeat: no-repeat;\n  background-position: 100% 0.4em;\n  padding-right: 1ch;\n}\n\n@media only screen and (max-width: 670px) {\n  .cyberParent {\n    background: linear-gradient(\n        90deg,\n        rgba(255, 255, 255, 0) 0%,\n        #ffc641 92.71%\n      ),\n      url(\"../assets/svgs/cyberSecurityAr-sm.svg\");\n\n    background-size: 100% auto;\n    background-repeat: no-repeat;\n    background-position: 100% 0%;\n    /* background-position: 100% 0%; */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
