import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip dir="rtl" {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#e6e6e6",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#efefef",
    color: "#11174899",
    padding: "36px 30px",
    fontSize: "22px",
    minWidth: "536px",
    textAlign: "right"
  },
  "@media (max-width: 960px)": {
    [`& .${tooltipClasses.tooltip}`]: {
      padding: "24px 20px",
      fontSize: "18px",
      minWidth: "300px",
    },
  },
  "@media (max-width: 576px)": {
    [`& .${tooltipClasses.tooltip}`]: {
      padding: "18px 16px",
      fontSize: "16px",
      minWidth: "200px",
    },
  },
}));

export default function CustomizedTooltips({
  note,
  btnText,
  btnClassName,
  onOpen,
  onClose,
}) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
    onClose();
  };

  const handleTooltipToggle = () => {
    setOpen(!open);
    if (!open) {
      onOpen();
    } else {
      onClose();
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <span className="cursor-pointer">
          <BootstrapTooltip
            title={note}
            open={open}
            onClose={handleTooltipClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Button>
              <span
                style={{ textTransform: "capitalize" }}
                className={` ${btnClassName}`}
                onClick={handleTooltipToggle}
              >
                {btnText}
              </span>
            </Button>
          </BootstrapTooltip>
        </span>
      </ClickAwayListener>
    </>
  );
}

CustomizedTooltips.defaultProps = {
  btnText: "اقرأ المزيد",
  note: "",
  btnClassName:
    "text-[#18A6AB] font-[500] text-[16px] tablet:text-[18px] laptop:text-[20px] desktop:text-[22px]",
  onOpen: () => {},
  onClose: () => {},
};
